import React from 'react';
import Home from '../components/home/Home';

function index({ user }) {
    return (
        <>
          <Home user={user}/>
        </>
    )
}

export default index